<template>
    <div>
      <b-card :title="t('表示設定')" class="mb-3">
        <small class="form-text text-muted">{{ t("ドラッグ&ドロップで商品詳細に表示する項目と並び順を設定してください。") }}</small>
        <div class="d-flex justify-content-between">
          <div class="drag-area">
            <h5>{{ t("表示する項目") }}</h5>
            <draggable
              class="list-group"
              tag="ul"
              v-model="visibleItems"
              v-bind="dragOptions"
              @start="drag = true"
              @end="drag = false"
              @add="onAdd"
            >
              <transition-group type="transition" :name="!drag ? 'flip-list' : null" mode="out-in">
                <li
                  class="list-group-item"
                  :class="{ 'dragging': drag }"
                  v-for="element in visibleItems"
                  :key="element.id"
                >
                  {{ t(element.name) }}
                </li>
              </transition-group>
            </draggable>
          </div>
          <div class="drag-area">
            <h5>{{ t("非表示の項目") }}</h5>
            <draggable
              class="list-group"
              tag="ul"
              v-model="hiddenItems"
              v-bind="dragOptions"
              @start="drag = true"
              @end="drag = false"
              @add="onAdd"
            >
              <transition-group type="transition" :name="!drag ? 'flip-list' : null" mode="out-in">
                <li
                  class="list-group-item"
                  :class="{ 'dragging': drag }"
                  v-for="element in hiddenItems"
                  :key="element.id"
                >
                  {{ t(element.name) }}
                </li>
              </transition-group>
            </draggable>
          </div>
        </div>

      </b-card>
      <b-button class="bg-eresa text-white" @click="saveSettings">{{ t('保存') }}</b-button>
      <loading-screen v-if="loadingScreen"></loading-screen>
      <b-modal id="save-settings-modal" :title='t("保存が完了しました")' ok-only>
        {{ t("設定情報を同期させるために、他の端末でERESAをご利用の際には一度ログアウトした後、再度ログインをお願いいたします。") }}
      </b-modal>
    </div>
    
</template>

<script>

import Draggable from 'vuedraggable';
import Utils from "@/mixins/utils";
import LoadingScreen from "@/components/LoadingScreen.vue";
import ProductBlocks from "@/mixins/ProductBlocks";

const DEFAULT_ITEMS = ProductBlocks.getBlocksInOrder();

export default {
  name: 'DisplayOrders',
  components: {
    LoadingScreen,
    Draggable
  },
  props: [],
  mixins: [Utils],
  data() {
    return {
    visibleItems: [],
    hiddenItems: [],
    loadingScreen: false,
    drag: false
    }
  },
  watch: {
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  async created() {
    // 検索条件の初期値設定
    this.selectedCountPerPage = 50;
    this.selectedRankingRange = 0;
  },
  async mounted() {
    const savedDisplayOrders = await this.$store.getters.getDisplayOrders;
    
    //設定が存在する場合は設定を反映
    if (savedDisplayOrders && savedDisplayOrders.visibleItems && savedDisplayOrders.hiddenItems) {
      const newItems = [];
      DEFAULT_ITEMS.forEach(item => {
        if (savedDisplayOrders.visibleItems[item.id] != void 0){
          this.visibleItems.splice(savedDisplayOrders.visibleItems[item.id], 0, item);
        } else if(savedDisplayOrders.hiddenItems[item.id] != void 0) {
          this.hiddenItems.splice(savedDisplayOrders.hiddenItems[item.id], 0, item);
        } else {
          // 過去に保存実績がないデータは新規データとして退避しておく
          newItems.push(item);
        }
      });

      // newItemsをvisibleItemsの末尾に追加
      this.visibleItems = this.visibleItems.concat(newItems);
    } else {
      // 設定が存在しない場合デフォルトを設定しておく
      this.visibleItems = DEFAULT_ITEMS;
    }
  },
  methods: {
    onAdd(evt) {
      // ghostが一瞬表示される問題に対応
      evt.item.classList.add("display-none");
    },
    async saveSettings() {
      const visibleItems = this.visibleItems.reduce((acc, cur, index) => {
        acc[cur.id] = index;
        return acc;
      }, {});

      const hiddenItems = this.hiddenItems.reduce((acc, cur, index) => {
        acc[cur.id] = index;
        return acc;
      }, {});

      const settings = {
        visibleItems: visibleItems,
        hiddenItems: hiddenItems
      }
      
      this.loadingScreen = true;

      try {
        await this.$store.dispatch('saveDisplayOrders', settings);
        
        this.$bvModal.show('save-settings-modal');
      } catch (error) {
        console.error("Error in saveDisplayOrders: ", error);
      } finally {
        this.loadingScreen = false;
      }
    },
  }
};
</script>


<style scoped>
.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.drag-area {
  width: 49%;
  min-height: 400px;
}
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #d8f1fd;
}

.list-group > span{
  min-height: 344px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.list-group-item {
  cursor: move;
  transition: background-color 0.3s ease; /* 背景色が滑らかに変化するようにする */
}

.display-none{
        display:none!important;
}

.list-group-item:hover:not(.dragging) {
  background-color: #d8f1fd;
}

</style>