<template>
  <div class="page-content">
    <div class="container pb-3">
      <h1 class="font-weight-bold pb-5 d-none d-sm-block">{{ t('各種設定') }}</h1>
      <div class="font-weight-bold pb-5 title-sm d-sm-none">{{ t('各種設定') }}</div>

      <b-card no-body class="font-weight-bold">
        <b-tabs pills card>
          <b-tab :title="t('検索設定')" active>
            <SearchConditions></SearchConditions>
          </b-tab>
          <b-tab :title="t('商品詳細')">
            <ProductDetailSettings></ProductDetailSettings>
          </b-tab>
          <b-tab :title="t('表示設定')">
            <DisplayOrders></DisplayOrders>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>
<script>

import AuthUtil from "@/mixins/authutil";
import Utils from "@/mixins/utils";
// 検索条件コンポーネント読み込み
import SearchConditions from '@/components/Settings/SearchConditions.vue';
// 商品詳細コンポーネント読み込み
import ProductDetailSettings from '@/components/Settings/ProductDetailSettings.vue';
// 並べ替えコンポーネント読み込み
import DisplayOrders from '@/components/Settings/DisplayOrders.vue';

export default {
  name: "Settings",
  data() {
    return {};
  },
  mixins: [AuthUtil, Utils],
  components: {SearchConditions, ProductDetailSettings,DisplayOrders},
  async created() {
  },
  async mounted() {
    // 有料会員チェック
    await this.validateSubscriber();
  },
  methods: {
  }
};
</script>

<style scoped>
.title-sm {
  font-size: 20px;
}
</style>
