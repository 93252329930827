<template>
    <div>
      <!-- 商品詳細の表示設定 -->
      <b-card :title="t('商品詳細の表示設定')" class="mb-3">
        <!-- ショップリンク設定 -->
        <b-form-group :label="t('各種ボタンの設定')">
          <small class="form-text text-muted">{{ t("※有効にしたいボタンにチェックを入れてください。") }}</small>
          <b-form-checkbox-group v-model="selectedShops" stacked>
            <b-form-checkbox v-for="shop in shops" :key="shop.name" :value="shop.name" switch>{{ t(shop.name) }}</b-form-checkbox>
          </b-form-checkbox-group>

          <small class="form-text text-muted">{{ t("※有効にしたいAIボタンにチェックを入れてください。") }}</small>
          <b-form-checkbox-group v-model="selectedAiButtons" stacked>
            <b-form-checkbox v-for="aiButton in aiButtons" :key="aiButton.name" :value="aiButton.name">{{ t(aiButton.label) }}</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
  
        <!-- グラフ表示設定 -->
        <b-form-group :label="t('推移グラフ及び日別データ設定')">
          <b-form-group :label="t('推移グラフ（種別）')">
            <b-form-select v-model="selectedChartDataType" :options="chartDataTypes"></b-form-select>
          </b-form-group>
          <b-form-group :label="t('推移グラフ（期間）')">
            <b-form-select v-model="selectedChartDataTerms" :options="chartDataTerms"></b-form-select>
          </b-form-group>
          <b-form-group :label="t('日別データ推移（種別）')">
            <b-form-select v-model="selectedHistoricalDataType" :options="historicalDataTypes"></b-form-select>
          </b-form-group>
          <b-form-group :label="t('日別データ推移（期間）')">
            <b-form-select v-model="selectedHistoricalDataTerms" :options="historicalDataTerms"></b-form-select>
          </b-form-group>
        </b-form-group>
        
        <b-button class="bg-eresa text-white" @click="saveSettings">{{ t('保存') }}</b-button>
      </b-card>
      <loading-screen v-if="loadingScreen"></loading-screen>
    </div>
</template>


  
<script>
import Utils from "@/mixins/utils";
import Shops from '@/mixins/Shops';
import AiButtons from '@/mixins/AiButtons';
import LoadingScreen from "@/components/LoadingScreen.vue";

const CHART_DATA_TYPES = Object.freeze({
  "ALL": "すべて",
  "NEW": "新品",
  "USED": "中古",
  "COLLECTOR": "コレクター"
});

const CHART_DATA_TERMS = Object.freeze({
  "MON1": "1ヵ月",
  "MON3": "3ヵ月",
  "MON6": "6ヵ月",
});

const HISTORICAL_DATA_TYPES = Object.freeze({
  "DEFAULT": "すべて",
  "NEW": "新品",
  "USED": "中古",
  "COLLECTOR": "コレクター"
});

const HISTORICAL_DATA_TERMS = Object.freeze({
  "MON1": "1ヵ月",
  "MON3": "3ヵ月",
  "MON6": "6ヵ月",
});

export default {
  name: 'ProductDetailSettings',
  mixins: [Utils],
  components: {
    LoadingScreen
  },
  data() {
    return {
      selectedShops: [],
      shops: [],
      selectedAiButtons: [],
      aiButtons: [],
      chartDataTypes: [],
      chartDataTerms: [],
      historicalDataTypes: [],
      historicalDataTerms: [],
      selectedChartDataType: null,
      selectedChartDataTerms: null,
      selectedHistoricalDataType: null,
      selectedHistoricalDataTerms: null,
      loadingScreen: false
    }
  },
  computed: {
    selectedShopsIndex() {
      const obj = {};
      this.shops.forEach(shop => {
        obj[shop.name] = this.selectedShops.includes(shop.name);
      });
      return obj;
    },
    selectedAiButtonsIndex() {
      const obj = {};
      this.aiButtons.forEach(aiButton => {
        obj[aiButton.name] = this.selectedAiButtons.includes(aiButton.name);
      });
      return obj;
    }
  },
  async created() {
    const region = this.isComEresa()? "COM" : "JP";
    //ショップの一覧を生成
    this.shops = Object.values(Shops).filter(shop => shop.isTargetRegion(region));
    //AIボタンの一覧を生成
    this.aiButtons = Object.values(AiButtons);

    //セレクトボックスの中身を翻訳しつつ生成
    this.chartDataTypes = Object.entries(CHART_DATA_TYPES).map(([value, text]) => ({ value, text: this.t(text) }));
    this.chartDataTerms = Object.entries(CHART_DATA_TERMS).map(([value, text]) => ({ value, text: this.t(text) }));
    this.historicalDataTypes = Object.entries(HISTORICAL_DATA_TYPES).map(([value, text]) => ({ value, text: this.t(text) }));
    this.historicalDataTerms = Object.entries(HISTORICAL_DATA_TERMS).map(([value, text]) => ({ value, text: this.t(text) }));

    //すべてのshopsを選択状態にする
    this.selectedShops = this.shops.map(shop => shop.name);
    //すべてのAIボタンを選択状態にする
    this.selectedAiButtons = this.aiButtons.map(aiButtons => aiButtons.name);
    //graphの初期値を設定
    this.selectedChartDataType = Object.keys(CHART_DATA_TYPES)[0]
    this.selectedChartDataTerms = Object.keys(CHART_DATA_TERMS)[0]
    this.selectedHistoricalDataType = Object.keys(HISTORICAL_DATA_TYPES)[0]
    this.selectedHistoricalDataTerms = Object.keys(HISTORICAL_DATA_TERMS)[0]
  },
  async mounted() {
    //storeから設定を取得
    const productDetailSettings = await this.$store.getters.getProductDetailSettings;

    //設定が存在する場合は設定を反映
    if (productDetailSettings) {
      this.selectedShops = productDetailSettings.displayShops ?? this.selectedShops;
      this.selectedAiButtons = productDetailSettings.displayAiButtons ?? this.selectedAiButtons;
      this.selectedChartDataType = productDetailSettings.defaultChartDataType ?? this.selectedChartDataType;
      this.selectedChartDataTerms = productDetailSettings.defaultChartDataTerms ?? this.selectedChartDataTerms;
      this.selectedHistoricalDataType = productDetailSettings.defaultHistoricalDataType ?? this.selectedHistoricalDataType;
      this.selectedHistoricalDataTerms = productDetailSettings.defaultHistoricalDataTerms ?? this.selectedHistoricalDataTerms;
    }
  },
  methods: {
    async saveSettings() {
      const settings = {
        displayShops: this.selectedShops,
        displayShopsIndex: this.selectedShopsIndex,
        displayAiButtons: this.selectedAiButtons,
        displayAiButtonsIndex: this.selectedAiButtonsIndex,
        defaultChartDataType: this.selectedChartDataType,
        defaultChartDataTerms: this.selectedChartDataTerms,
        defaultHistoricalDataType: this.selectedHistoricalDataType,
        defaultHistoricalDataTerms: this.selectedHistoricalDataTerms
      };
      this.loadingScreen = true;

      try {
        await this.$store.dispatch('saveProductDetailSettings', settings);
      } catch (error) {
        console.error("Error in saveProductDetailSettings: ", error);
      } finally {
        this.loadingScreen = false;
      }
    },
  }
}
</script>